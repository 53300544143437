import React, { Component } from 'react';
import styles from './loader.scss';

export default class Loader extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
		  <div className={'loader' + (this.props.className?' ' + this.props.className:'')}>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 99 113">
          <path strokeWidth="2" stroke="#000" d="M81,12.6c-5.4,0-9.6,4.3-9.6,9.7V46c0,3-1.5,5.5-4,6.5c-2.5,1-5.4,0.3-7.6-1.9L28,15.4
            c-3.6-3.6-9.8-3.7-13.5-0.2c-3.8,3.6-2.9,9.4,0.1,13L53,70.6l0,0.1c1.1,1.1,1.5,2.7,1,3.9c-0.5,1.3-1.8,2-3.5,2H24.2
            c-5.7,0-9.1,4.6-9.1,9.1c0,5.6,3.8,9.7,9.1,9.7H81c5.2,0,9.1-4.2,9.1-9.7V22.3C90.1,17,86,12.6,81,12.6z" style={{strokeDashoffset: 8.06242}}>
          </path>
        </svg>
      </div>
		);
	}
}
