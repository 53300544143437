import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import './index.scss';
import Close from '@material-ui/icons/Close';
import Classnames from 'classnames';

export default (props) => {
  let children = props.open ? props.children : [],
    propsClassName = props.className || '',
    className = Classnames({
      'modal': true,
      'modal--no-window': props.window === false,
      [propsClassName]: !!propsClassName,
    });

  if (!(children instanceof Array)) {
    children = [children];
  }

  function goToOriginPath() {
    const originPath = window.location.origin;
    window.open(originPath, '_self');
  }

  return <CSSTransitionGroup
    transitionName="fadeIn"
    transitionEnterTimeout={300}
    transitionLeaveTimeout={300}>
    {props.open && <div className={className}>
      <div className="modal__window">
        {children && <div className={`modal__body`}>{children}</div>}
        {props.hideCloseButton || <Close className="modal__close" onClick={props.onClose && !props.fixed ? props.onClose : goToOriginPath} color="white"/>}
      </div>
    </div>}
  </CSSTransitionGroup>;
};
