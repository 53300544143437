import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import './index.scss';
import i18next from 'i18next';
import classNames from 'classnames';
import IconLogo from 'static/home/icons/logo.svg';

class Index extends Component {
  constructor(props) {
    super(props);

    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleRegistrationClick = this.handleRegistrationClick.bind(this);
  }
  handleToggleMenu() {
    this.props.onToggleMenu();
  }
  handleLoginClick() {
    this.handleToggleMenu();
    this.props.openLoginModal();
  }
  handleRegistrationClick() {
    this.handleToggleMenu();
    this.props.openRegistrationModal();
  }
  render() {
    const { t, pageLinksVisible, pageLinks, onToggleMenu, open, currentUser } = this.props;
    let pageLinkElements,
      signButtons;

    if (pageLinksVisible) {
      let links = pageLinks.map((pageLink, i) => {

        let checkExternalLink = new RegExp("^(http|https)://", "i");

        if (!pageLink.to && pageLink.subLinks) {
          let subLinks = pageLink.subLinks.map((pageLink, j) => {
            return <li key={i+':'+j} className="mobile-menu__item">
              <NavLink className="mobile-menu__link" activeClassName="isSelected" to={pageLink.to}
                onClick={onToggleMenu}>{t(pageLink.translate)}</NavLink>
            </li>;
          });

          return subLinks;
        }

        if (checkExternalLink.test(pageLink.to)) {
          return <li key={i} className="mobile-menu__item">
              <a className="mobile-menu__link" activeClassName="isSelected" target="_blank" rel="noopener noreferrer" href={pageLink.to} >{t(pageLink.translate)}</a>
            </li>;
        }

        return <li key={i} className="mobile-menu__item">
          <NavLink className="mobile-menu__link" activeClassName="isSelected" to={pageLink.to}
            onClick={onToggleMenu}>{t(pageLink.translate)}</NavLink>
        </li>;
      });

      pageLinkElements = <ul className="mobile-menu__links">
        {links}
      </ul>;
    } else {
      pageLinkElements = <div></div>;
    }

    if (currentUser.state === 'loggedOut') {
      signButtons =
        <div className="mobile-menu__login-buttons">
          <a className={classNames('mobile-menu__signup-button button login-btn', i18next.language.includes('pl') && 'no-sides-margin')} onClick={this.handleRegistrationClick}>
            {t('navBar.register')}
          </a>
          <a className={classNames('mobile-menu__login-button button login-btn button--transparent', i18next.language.includes('pl') && 'no-sides-margin')} onClick={this.handleLoginClick}>
            {t('navBar.login')}
          </a>
        </div>;
    }

    return (
      <React.Fragment>
        {open && (
          <div>
            <div className="mobile-menu__close-btn--active" onClick={this.handleToggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={`mobile-menu${open?' mobile-menu--active':''}`}>
              <div className="mobile-menu__header">
                <div className="mobile-menu__logo">
                  <IconLogo/>
                </div>
                {signButtons}
              </div>
              {pageLinkElements}
              <ul className="mobile-menu__links mobile-menu__links--second">
                <li className="mobile-menu__item mobile-menu__item--second">
                  <a className="mobile-menu__link mobile-menu__link--second" href="http://www.facebook.com/evryplace/" target="_blank">Facebook</a>
                </li>
                <li className="mobile-menu__item mobile-menu__item--second">
                  <a className="mobile-menu__link mobile-menu__link--second" href="http://www.linkedin.com/company/evryplace" target="_blank">Linkedin</a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default translate('translations')(Index);
