import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconVR(props) {
  return (
    <SvgIcon {...props}>
      <path d="M13.8 9.9748L12.375 8.5498L15.95 3.9998H7.82502L5.82502 1.9998H18C18.4167 1.9998 18.7167 2.18314 18.9 2.5498C19.0834 2.91647 19.05 3.26647 18.8 3.5998L13.8 9.9748ZM18.775 20.5998L13 14.8248V16.9998C13 17.2831 12.9044 17.5205 12.713 17.7118C12.521 17.9038 12.2834 17.9998 12 17.9998H10C9.71669 17.9998 9.47936 17.9038 9.28802 17.7118C9.09602 17.5205 9.00002 17.2831 9.00002 16.9998V10.8248L0.400024 2.2248L1.80002 0.799805L20.2 19.1998L18.775 20.5998Z"/>
    </SvgIcon>
  );
}
