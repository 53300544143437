import React, { Component } from "react";
import { translate } from "react-i18next";
import './index.scss';
import Asari from "home/logotype-set/asari.svg";
import CBRE from "home/logotype-set/CBRE.svg";
import Delaval from "home/logotype-set/delaval.svg";
import JLL from "home/logotype-set/jll.svg";
import Maisonette from "home/logotype-set/Maisonette.svg";
import Skanska from 'home/logotype-set/skanska.svg';
import Capgemini from 'home/logotype-set/Capgemini_201x_logo.svg';
import Ceresit from 'home/logotype-set/Ceresit-logo.svg';
import DeLavalColor from 'home/logotype-set/delaval-vector-logo.svg';
import Henkel from 'home/logotype-set/Henkel-Logo.svg';
import Classnames from "classnames";

class LogotypeStrip extends Component {
  constructor(props) {
    super(props);

  }
  render() {
    const { t, trainings } = this.props;

    let className = Classnames({
      'logotype-set': true,
    });

    return (
      <section className={className}>
        <div className="logotype-set__wrapper wrapper">
          <div className="logotype-set__logos">
            <span className="logotype-set__text">{t("home.usedBy")}</span>
            {trainings ? (
              <React.Fragment>
                <Henkel />
                <Ceresit />
                <DeLavalColor />
                <Capgemini />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Asari />
                <CBRE />
                <Delaval />
                <JLL />
                <Maisonette />
                <Skanska />
              </React.Fragment>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default translate("translations")(LogotypeStrip);
