import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-form';
import { red, green } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import './index.scss';

const input = {
  borderRadius: 4,
  border: '1px solid grey',
  padding: '10px 12px'
}
const styles = theme => ({
  root: {
    marginBottom: '5px'
  },
  input: {
    ...input,
    transition: theme.transitions.create(['border-color']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
    }
  },
  helperText: {
    margin: '5px 0 5px 3px'
  },
  error: {
    ...input,
    borderColor: red[500],
    '&:focus': {
      borderColor: red[900]
    }
  },
});


const HintTextField = props => (
  <div>
    <Field validate={props.validate} field={props.field}>
      {fieldApi => {
        // Remember to pull off everything you dont want ending up on the <input>
        // thats why we pull off onChange, onBlur, and field
        // Note, the ...rest is important because it allows you to pass any
        // additional fields to the internal <input>.
        const { onChange, onBlur, classes, field, validate, multiline, rows, ...rest } = props,
          { value, error, warning, success, setValue, setTouched } = fieldApi,
          errorStyle = { color: red[500], borderColor: red[500] },
          text = (error ? error : null),
          style = (error ? errorStyle : {});
        return (
          <FormControl
            value={value || ''}
            onChange={e => {
              setValue(e.target.value);
              if (onChange) {
                onChange(e.target.value, e);
              }
            }}
            onBlur={e => {
              setTouched();
              if (onBlur) {
                onBlur(e);
              }
            }}
            error={error}
            fullWidth={true}
          >
            <InputBase {...rest} placeholder={props.hintText} classes={{ root: classes.root, input: error ? classes.error : classes.input }} multiline={multiline} rows={rows} />

            <FormHelperText className={classes.helperText}>{text}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  </div>
);

export default withStyles(styles)(HintTextField);
