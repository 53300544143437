import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconSpaceplan(props) {
  return (
    <SvgIcon {...props}>
      <path d="M23.4,13.7l-4-2l4-2.3c0.2-0.1,0.3-0.4,0.3-0.6c0-0.3-0.2-0.5-0.4-0.6L12.3,2.8c-0.2-0.1-0.4-0.1-0.6,0L0.6,8.2C0.4,8.3,0.3,8.6,0.3,8.8c0,0.3,0.1,0.5,0.3,0.6l4,2.3l-4,2c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.3,0.1,0.5,0.3,0.6l11.1,6.3c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l11.1-6.3c0.2-0.1,0.3-0.4,0.3-0.6C23.7,14,23.6,13.8,23.4,13.7z M12,4.1l6.2,3.1l-3.6,2.1L8.5,5.9L12,4.1z M7,6.6l7.3,4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l4.7-2.7l2,1L12,14.4L6.1,11l2.5-1.3C8.9,9.5,9,9.1,8.9,8.8C8.7,8.4,8.3,8.3,8,8.5l-3.3,1.7L2.4,8.9L7,6.6z M3.8,13.7l3.4,1.9l-1.3,0.8l-3.5-2L3.8,13.7z M19.2,15.7l-2.4-1.4c-0.3-0.2-0.7-0.1-0.9,0.2c-0.2,0.3-0.1,0.7,0.2,0.9l1.7,1L12,19.9l-4.7-2.7l1.7-1c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.1-0.5-0.3-0.6l-3.6-2.1l0.8-0.4l5.6,3.2c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1l5.6-3.2l3.7,1.8L19.2,15.7z"/>
    </SvgIcon>
  );
};
