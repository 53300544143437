import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function IconLabel(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18 7L13.65 13.15C13.4667 13.4167 13.2293 13.625 12.938 13.775C12.646 13.925 12.3333 14 12 14H2C1.45 14 0.979 13.8043 0.587 13.413C0.195667 13.021 0 12.55 0 12V2C0 1.45 0.195667 0.979333 0.587 0.588C0.979 0.196 1.45 0 2 0H12C12.3333 0 12.646 0.0749999 12.938 0.225C13.2293 0.375 13.4667 0.583333 13.65 0.85L18 7ZM15.55 7L12 2H2V12H12L15.55 7ZM2 7V12V2V7Z" fill="#006783"/>
    </SvgIcon>
  );
};
